<!--
 * @Author: Raymonda
 * @Date: 2020-06-12 14:54:38
 * @LastEditTime: 2020-06-12 19:15:01
 * @LastEditors: Raymonda
 * @Description: Description
 * @当你不能够再拥有，你唯一可以做的，就是令自己不要忘记！
-->
<template>
    <div class="post-detail">
        <div class="go-back" @click="goBack">
            <Icon
                class="go-back-icon"
                type="ios-arrow-dropleft-circle"
            />返回列表
        </div>
        <div class="post-item" v-if="post">
            <div v-if="post.user" class="post-user">
                <!-- <img
                    class="user-face"
                    :src="post.user.avatar || defaultFace"
                    alt=""
                /> -->
                <div class="user-info">
                    <Tag size="mini" :color="getColor(post.user.userType)">{{
                        post.user.userType | userTypeFilter
                    }}</Tag>
                    <span class="user-name">{{
                        post.user.nickname || post.user.phone || "暂无姓名"
                    }}</span>
                </div>
            </div>
            <div class="post-main">
                <div class="post-title">{{ post.title }}</div>
                <div class="post-content">{{ post.content }}</div>
                <div class="post-info">
                    <div class="post-time">{{ post.createdAt | fmt_time }}</div>
                    <Icon class="comment-icon" type="md-chatboxes" />
                    <div class="comment-count">{{ post.commentCount }}</div>
                </div>
            </div>
        </div>
        <div class="comment-list" v-if="commentList.length">
            <div
                v-for="(cmt, ind) in commentList"
                :key="'cmt' + ind"
                class="comment-item"
            >
                <div v-if="cmt.user" class="comment-main">
                    <!-- <img
                        class="user-face"
                        :src="cmt.user.avatar || defaultFace"
                        alt=""
                    /> -->
                    <div class="user-info">
                        <Tag size="mini" :color="getColor(cmt.user.userType)">{{
                            cmt.user.userType | userTypeFilter
                        }}</Tag>
                        <span class="user-name">{{
                            cmt.user.nickname || cmt.user.phone || "暂无姓名"
                        }}</span>
                    </div>
                </div>
                <div class="comment-content">
                    <div class="comment-text">{{ cmt.content }}</div>
                    <div class="comment-time">
                        {{ cmt.createdAt | fmt_time }}
                    </div>
                </div>
            </div>
        </div>
        <div class="empty-list" v-else>
            <img
                src="http://cdn.sjtuair.com/6694b050-3367-11ea-abd0-69bd09a4c42b.png"
                alt=""
            />
            <div class="empty-tip">还没有回帖，赶快回帖抢沙发吧</div>
        </div>
        <Page @on-change="changePage" :total="commentTotal" show-total />
        <comment-input
            title="回帖"
            :show-title="false"
            :cb="true"
            @submit="commentPost"
        ></comment-input>
    </div>
</template>
<script>
import { HttpUtil } from "../../assets/util/http";
import CommentInput from "@/compontents/home/commentInput";
export default {
    name: "pubilsh-detail",
    components: { CommentInput },
    data() {
        return {
            post: null,
            id: "",
            commentList: [],
            commentTotal: 0,
            pageNum: 0,
            pageSize: 10,
        };
    },
    computed: {
        defaultFace() {
            return this.$store.state.defaultFace;
        },
    },
    created() {
        this.getInfo();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        getColor(val) {
            switch (val) {
                case "visitor":
                    return "orange";
                case "teacher":
                    return "primary";
                case "student":
                    return "success";
                default:
                    return "default";
            }
        },
        async getInfo() {
            this.id = this.$route.query.id;
            if (!this.id) {
                this.$Message.error("未知帖子");
                setTimeout(() => {
                    return this.$router.go(-1);
                }, 1111);
            }
            this.post = await HttpUtil.post("/post/detail", { id: this.id });
            this.getComment();
        },
        async getComment() {
            let res = await HttpUtil.post("/post/comment", {
                id: this.id,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            });
            if (res) {
                this.commentList = res.result || [];
                this.commentTotal = res.count ?? 0;
            }
        },
        async commentPost(data) {
            await HttpUtil.post("/comment/add", {
                ...data,
                post: this.id,
            });
            this.$Message.success("回帖成功");
            this.getInfo();
        },
        changePage(num) {
            this.pageNum = num - 1;
            this.getComment();
        },
    },
};
</script>
<style lang="less" scoped>
.post-detail {
    padding-bottom: 30px;
    .ivu-page {
        margin-bottom: 20px;
        text-align: right;
        margin-right: 15px;
    }
    .go-back {
        font-size: 16px;
        text-align: left;
        margin-bottom: 15px;
        cursor: pointer;
        .go-back-icon {
            font-size: 18px;
            margin-right: 5px;
            margin-top: -4px;
            color: #4397ff;
        }
    }
    .comment-input {
        margin: 0 15px;
        width: auto;
    }
    .empty-list {
        img {
            width: 300px;
        }
        .empty-tip {
            margin: 30px auto;
            font-size: 18px;
        }
    }
    .comment-list {
        padding: 10px 20px;
        border: 1px solid #dcdee2;
        margin: 15px;
        border-radius: 8px;
        box-shadow: 0px 1px 10px 0px rgba(179, 179, 179, 0.5);
    }
    .comment-item {
        display: flex;
        // align-items: center;
        border-bottom: 1px solid #dcdee2;
        padding: 15px 0;
        &:last-child {
            border-bottom: none;
        }
    }
    .comment-main {
        .user-face {
            width: 3vw;
            height: 3vw;
            border-radius: 50%;
            object-fit: cover;
        }
        .user-info {
            // margin-top: 10px;
            display: flex;
            align-items: center;
            .user-name {
                font-size: 14px;
                color: #333;
                margin-left: 5px;
                max-width: 100px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
    .comment-content {
        display: flex;
        margin-left: 30px;
        flex-direction: column;
        flex: 1;
        align-self: stretch;
        .comment-text {
            flex: 1;
            font-size: 16px;
            text-align: left;
            color: #333;
        }
        .comment-time {
            color: #666;
            font-size: 14px;
            text-align: right;
        }
    }
}
.post-item {
    display: flex;
    border: 1px solid #dcdee2;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 1px 10px 0px rgba(179, 179, 179, 0.5);
    .post-user {
        .user-face {
            width: 4vw;
            height: 4vw;
            border-radius: 50%;
            object-fit: cover;
        }
        .user-info {
            // margin-top: 10px;
            display: flex;
            align-items: center;
            .user-name {
                font-size: 14px;
                color: #333;
                margin-left: 5px;
                max-width: 100px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
    .post-main {
        margin-left: 30px;
        text-align: left;
        line-height: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        .post-title {
            font-size: 18px;
            color: #000;
            flex-shrink: 0;
        }
        .post-content {
            font-size: 16px;
            color: #555;
            flex: 1;
            margin-top: 10px;
        }
        .post-info {
            font-size: 14px;
            color: #666;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            line-height: 1;
            .praise-icon {
                font-size: 18px;
                margin-left: 15px;
                margin-top: -5px;
            }
            .praise-count {
                margin-left: 5px;
            }
            .comment-icon {
                font-size: 18px;
                margin-left: 15px;
                margin-top: -1px;
            }
            .comment-count {
                margin-left: 5px;
            }
        }
    }
    .praised {
        color: #4397ff;
    }
}
</style>
